import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: '', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: '',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'smartgeneration.jpg',
    title: 'smartgeneration TZ(on maintanence)',
    info:
      'website for a non profit organization who are dedicated to help youth realize their potential and facilitate them in using their talents and abilities to bring positive impact to their societies.',
    info2: '',
    url: 'https://powerful-waters-93922.herokuapp.com/',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'prism.png',
    title: 'Prism communication',
    info:
      'Website for Prism communications who`s aim is providing your business with creative solutions to help it win the market. their experienced team on branding and content creation will apply the best strategy to design and communicate your brand message to the right audience.',
    info2: '',
    url: 'https://prismtz.co.tz',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'msafiritravels.png',
    title: 'Msafiri travels',
    info:
      'Tourism company based in Tanzania, website based on exploring different safari experience and booking.',
    info2: '',
    url: 'https://msafiritravels.com',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'jijisolutions.png',
    title: 'jiji soluions(under development)',
    info:
      'Jiji guides brands into the most effective and suited way of communication that will properly translate all the key messages and generate the most genuine and lasting impression from their customers.',
    info2: '',
    url: 'https://guarded-waters-46410.herokuapp.com/',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/kenethsimon',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/keneth-thomas-b94b24123/',
    },
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://www.instagram.com/the_real_keneth_thomas/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/kennethsimon',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
